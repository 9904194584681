import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '@components/elements/Icon';
import Paragraph from '@components/elements/Paragraph';
import FlexBox from '@components/containers/FlexBox';
import style from '@constants/style';

import downloadDocument from '@assets/img/icons/download-document.svg';

const StyledDocumentBox = styled('button')`
  box-sizing: content-box;
  border: none;
  width: calc(10rem - 4rem);
  background-color: ${style.colours.secondaryLightGray3};
  display: flex;
  align-items: stretch;
  flex-direction: column;
  cursor: pointer;
  padding: 1rem 2rem;
`;

const DocumentBox = ({
  onClick,
  children,
}) => (
  <StyledDocumentBox onClick={onClick}>
    <Icon icon={downloadDocument} iconWidth="6rem" marginTop="none" marginBottom="none" />
    <FlexBox marginTop="small" justifyContent="center" alignItems="center" flexGrow="1">
      <Paragraph textAlign="center">{children}</Paragraph>
    </FlexBox>
  </StyledDocumentBox>
);

DocumentBox.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DocumentBox;
