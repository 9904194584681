import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import useRehydrated from '@utils/useRehydrated';

import { fetchUser } from '@redux/actions/user';

const useCustomerBankAccounts = (bankAccountId) => {
  const rehydrated = useRehydrated();
  const dispatch = useDispatch();

  const coApplicantAccount = {
    label: 'Medlåntagares bankkonto',
    value: bankAccountId,
    disabled: true,
    isSigned: true,
  };

  const userId = useSelector(({ user }) => user.userId);
  const loading = useSelector(({ user }) => !rehydrated || user.loading);
  const bankAccounts = useSelector(({ user }) => user.bankDetails.bankAccounts, shallowEqual);
  const {
    details: bankAccountDetails,
    isOwnedByUser,
  } = useSelector(
    ({ user }) => {
      const details = user.bankDetails.bankAccounts
        .find((bankAccount) => bankAccount.id === bankAccountId);
      return bankAccountId && !details
        ? { isOwnedByUser: false, details: coApplicantAccount }
        : { isOwnedByUser: true, details };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (rehydrated && !userId) dispatch(fetchUser());
  }, [rehydrated, userId, dispatch]);

  const signedBankAccounts = useMemo(() => {
    const accounts = bankAccounts.filter((bankAccount) => bankAccount.isSigned);
    if (bankAccountId && !isOwnedByUser) {
      accounts.push(bankAccountDetails);
    }
    return accounts;
  }, [bankAccounts, bankAccountId, bankAccountDetails, isOwnedByUser]);
  const signableBankAccounts = useMemo(
    () => bankAccounts
      .filter((bankAccount) => !bankAccount.isSigned && bankAccount.isValidForPayin),
    [bankAccounts],
  );

  return {
    loading,
    bankAccounts,
    signedBankAccounts,
    signableBankAccounts,
    bankAccountDetails,
  };
};

export default useCustomerBankAccounts;
