import React from 'react';
import PropTypes from 'prop-types';

import useGetLoan from '@utils/useGetLoan';
import urls from '@constants/urls';
import getUrl from '@utils/getUrl';
import LastTransactions from '@components/containers/LastTransactions';

const LoanLastTransactions = ({ loanId }) => {
  const { loan, loading } = useGetLoan(loanId);

  const transactions = (loan && loan.transactions) || [];

  const viewMoreurl = getUrl(urls.HOME.MY_LOANS.TRANSACTIONS, { loanId });

  return (
    <LastTransactions
      loading={loading}
      transactions={transactions}
      viewMoreurl={viewMoreurl}
    />
  );
};

LoanLastTransactions.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanLastTransactions;
