import React from 'react';
import PropTypes from 'prop-types';

import getUrl from '@utils/getUrl';

import urls from '@constants/urls';

import LoanDetailsCard from '@components/modules/LoanDetailsCard';
import ConnectAutogiro from '@components/modules/ConnectAutogiro';
import LoanMonthlyPaymentCard from '@components/modules/LoanMonthlyPaymentCard';
import LoanAgreements from '@components/modules/LoanAgreements';
import LoanLastTransactions from '@components/modules/LoanLastTransactions';

import LoggedPage from '@components/containers/LoggedPage';

const LoanView = ({ loanId }) => (
  <LoggedPage
    title="Lån"
    previousUrl={getUrl(urls.HOME)}
  >
    <LoanDetailsCard loanId={loanId} />
    <LoanMonthlyPaymentCard loanId={loanId} />
    <ConnectAutogiro loanId={loanId} />
    <LoanLastTransactions loanId={loanId} />
    <LoanAgreements loanId={loanId} />
  </LoggedPage>
);

LoanView.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanView;
