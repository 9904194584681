import styled from 'styled-components';
import style from '@constants/style';
import { StyledTableRow } from './TableRow';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${(props) => (props.fixedSize ? 'table-layout: fixed;' : '')}

  ${StyledTableRow} {
    ${(props) => (props.borderAfter ? `border-bottom: ${style.tableBorderStyle};` : `border-top: ${style.tableBorderStyle};`)};
  }
`;

const Table = StyledTable;

export default Table;
