import React from 'react';
import PropTypes from 'prop-types';

import FlexBox from '@components/containers/FlexBox';
import Transaction from '@components/containers/Transaction';
import Card from '@components/containers/Card';
import Headline from '@components/elements/Headline';
import Table from '@components/elements/table/Table';
import Anchor from '@components/elements/Anchor';
import Paragraph from '@components/elements/Paragraph';

const LastTransactions = ({
  loading, transactions, viewMoreurl,
}) => (
  <Card loading={loading}>
    <FlexBox alignItems="center" marginBottom="regular">
      <Headline fontWeight="bold" fontSize="tiny" as="h2">Senaste transaktioner</Headline>
    </FlexBox>
    {transactions.length ? (
      <>
        <FlexBox overflow="auto">
          <Table borderAfter>
            <tbody>
              {transactions
                .slice()
                .reverse()
                .slice(0, 3).map((transaction) => (
                  <Transaction key={transaction._id} transaction={transaction} />
                ))}
            </tbody>
          </Table>
        </FlexBox>
        <FlexBox justifyContent="center" marginTop="regular">
          <Anchor underlined to={viewMoreurl}>Visa mer</Anchor>
        </FlexBox>
      </>
    ) : (
      <FlexBox alignItems="center" flexDirection="column">
        <Paragraph>Inga transaktioner än.</Paragraph>
      </FlexBox>
    )}
  </Card>
);

LastTransactions.propTypes = {
  loading: PropTypes.bool.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      paymentDetails: PropTypes.shape({
        lender: PropTypes.string,
        accountNumber: PropTypes.string.isRequired,
        bankAccountType: PropTypes.string.isRequired,
        ocrNumber: PropTypes.number,
      }),
    }),
  ).isRequired,
  viewMoreurl: PropTypes.string.isRequired,
};

export default LastTransactions;
