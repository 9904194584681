import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import FlexBox from '@components/containers/FlexBox';
import TableRow from '@components/elements/table/TableRow';
import TableCell from '@components/elements/table/TableCell';
import Icon from '@components/elements/Icon';
import numberFormat from '@utils/numberFormat';

import CreditCardIcon from '@assets/img/icons/credit-card.svg';

const mapTypeLabel = {
  payOut: 'Utbetalning',
  payIn: 'Inbetalning',
  interest: 'Ränta',
  fee: 'Avgift',
  internal: 'Överföring',
  adjustment: 'Justering',
};

const Transaction = ({ transaction }) => (
  <TableRow>
    <TableCell>
      <FlexBox>
        <FlexBox alignItems="center" marginRight="small">
          <Icon icon={CreditCardIcon} marginLeft="none" marginRight="none" />
        </FlexBox>
        <FlexBox alignItems="center" flexWrap="wrap" justifyContent="space-between" flexGrow="1">
          <FlexBox marginRight="regular" minWidth="6.5rem"><strong>{mapTypeLabel[transaction.type] || ''}</strong></FlexBox>
          <FlexBox minWidth="7rem">{DateTime.fromISO(transaction.date).toFormat('dd MMM yyyy')}</FlexBox>
        </FlexBox>
      </FlexBox>
    </TableCell>
    <TableCell textAlign="right" fontWeight="bold">{numberFormat(-transaction.amount, 2, { unit: 'kr', withSign: true })}</TableCell>
  </TableRow>
);

Transaction.propTypes = {
  transaction: PropTypes.shape({
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
};

export default Transaction;
