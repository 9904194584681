import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { showModal } from '@redux/actions/ui';
import modalTypes from '@components/modals/types';
import Icon from '@components/elements/Icon';
import Anchor from '@components/elements/Anchor';
import questionMark from '@assets/img/icons/question-mark.svg?inline';

const StyledInfoButton = styled(Icon)`
  color: ${({ theme }) => theme.primaryColour}};
  vertical-align: -3px;
`;

const InfoButton = ({
  title,
  helpText,
  label,
}) => {
  const dispatch = useDispatch();

  const showInfoModal = useCallback((e) => {
    e.preventDefault();
    dispatch(showModal({
      type: modalTypes.INFO,
      props: {
        title,
        helpText,
      },
    }));
  }, [dispatch, helpText, title]);

  if (label) {
    return (
      <Anchor onClick={showInfoModal}>
        {label}&nbsp;<StyledInfoButton icon={questionMark} iconWidth="1.2rem" />
      </Anchor>
    );
  }

  return (
    <StyledInfoButton
      icon={questionMark}
      onClick={showInfoModal}
      iconWidth="1.2rem"
    />
  );
};

InfoButton.propTypes = {
  title: PropTypes.string.isRequired,
  helpText: PropTypes.node.isRequired,
  label: PropTypes.node,
};

InfoButton.defaultProps = {
  label: undefined,
};

export default InfoButton;
