import React from 'react';
import PropTypes from 'prop-types';

import AccountDetailsCard from '@components/containers/AccountDetailsCard';

import { IsInsuredBadge } from '@components/elements/Badge';

import useGetLoan from '@utils/useGetLoan';

import numberFormat from '@utils/numberFormat';

const LoanDetailsCard = ({ loanId }) => {
  const { loan, loading } = useGetLoan(loanId);
  const mainAmountValue = loan ? numberFormat(loan.todaySolvingAmount, 2, { unit: 'kr' }) : '';

  const publicId = `Lånenummer: ${loan ? loan.publicId : ''}`;

  const accountDetails = [];
  accountDetails.push({
    title: 'Startbelopp',
    value: loan && loan.terms ? numberFormat(loan.terms.amount, 2, { unit: 'kr' }) : '',
  });
  accountDetails.push({
    title: 'Ränta',
    value: loan && loan.currentTerms ? numberFormat(loan.currentTerms.nominalRate, 2, { unit: '%' }) : '',
  });
  accountDetails.push({
    title: 'Återstående inbetalningar',
    value: loan ? numberFormat(loan.estimatedRepaymentTime, 0) : 0,
  });

  return (
    <AccountDetailsCard
      title="Lån"
      loading={loading}
      mainAmountTitle="Nuvarande skuld"
      mainAmountValue={mainAmountValue}
      badge={<IsInsuredBadge loanId={loanId} />}
      publicId={publicId}
      accountDetails={accountDetails}
    />
  );
};

LoanDetailsCard.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanDetailsCard;
