import React from 'react';
import PropTypes from 'prop-types';

import MonthlyPayment from '@components/containers/MonthlyPayment';

import numberFormat from '@utils/numberFormat';
import useGetLoan from '@utils/useGetLoan';
import getUrl from '@utils/getUrl';

import urls from '@constants/urls';

const LoanMonthlyPaymentCard = ({ loanId }) => {
  const { loan, loading } = useGetLoan(loanId);

  const title = 'Månadsbetalning';

  const bankAccountIdPayin = loan && loan.bankAccountIdPayin;
  const hasPassedInvoicesPayed = loan && loan.passedInvoicesPayed;
  const hasPaymentPlan = true;

  const nextPaymentAmount = loan ? numberFormat(loan.monthlyPayment, 2, { unit: 'kr' }) : '';

  const paymentPlanUrl = getUrl(urls.HOME.MY_LOANS.PAYMENT_PLAN, { loanId });

  return (
    <MonthlyPayment
      loading={loading}
      title={title}
      bankAccountIdPayin={bankAccountIdPayin}
      hasPassedInvoicesPayed={hasPassedInvoicesPayed}
      hasPaymentPlan={hasPaymentPlan}
      nextPaymentAmount={nextPaymentAmount}
      paymentPlanUrl={paymentPlanUrl}
    />
  );
};

LoanMonthlyPaymentCard.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanMonthlyPaymentCard;
