import React from 'react';
import PropTypes from 'prop-types';

import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';
import ParagraphWithIcon from '@components/containers/ParagraphWithIcon';

import InfoButton from '@components/elements/InfoButton';
import Badge from '@components/elements/Badge';
import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';
import Anchor from '@components/elements/Anchor';

import style from '@constants/style';
import helpTexts from '@constants/helpTexts';

import calendarIcon from '@assets/img/icons/calendar.svg';
import onTimeIcon from '@assets/img/icons/on-time.svg';

const PaymentChannelBadge = ({ bankAccountIdPayin }) => (bankAccountIdPayin
  ? <Badge backgroundColor="tertiaryPurple">Via autogiro</Badge>
  : <Badge backgroundColor="tertiaryBlue">Via faktura</Badge>);

PaymentChannelBadge.propTypes = {
  bankAccountIdPayin: PropTypes.string,
};

PaymentChannelBadge.defaultProps = {
  bankAccountIdPayin: undefined,
};

const MonthlyPayment = ({
  loading, title, bankAccountIdPayin, hasPassedInvoicesPayed,
  hasPaymentPlan, nextPaymentAmount, paymentPlanUrl,
}) => (
  <Card loading={loading}>
    <FlexBox alignItems="center" marginBottom="small">
      <Headline fontWeight="bold" fontSize="tiny" as="h2" marginBottom="none" marginRight="small">{title}</Headline>
      <PaymentChannelBadge bankAccountIdPayin={bankAccountIdPayin} />
    </FlexBox>
    <FlexBox alignItems="center" justifyContent="center" flexWrap="wrap">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        margin="regular"
      >
        <Paragraph marginBottom="none">Nästa betalning:</Paragraph>
        <FlexBox
          paddingRight="regular"
          paddingBottom="tiny"
          paddingTop="tiny"
          paddingLeft="regular"
          justifyContent="center"
          backgroundColor="secondaryLightGray3"
        >
          <Paragraph fontWeight="bold" marginBottom="none">{nextPaymentAmount}</Paragraph>
        </FlexBox>
        <ParagraphWithIcon icon={calendarIcon}>28:e varje månad</ParagraphWithIcon>
      </FlexBox>
      {hasPassedInvoicesPayed ? (
        <FlexBox
          backgroundColor="tertiarySuccess"
          backgroundOpacity={0.1}
          borderColor={style.colours.tertiarySuccess}
          borderWidth="2px"
          borderStyle="solid"
          alignItems="center"
          padding="small"
          margin="regular"
        >
          <ParagraphWithIcon colour="tertiarySuccess" icon={onTimeIcon}>Du är i tid</ParagraphWithIcon>
        </FlexBox>
      ) : (
        <FlexBox
          backgroundColor="tertiaryFailure"
          backgroundOpacity={0.1}
          borderColor={style.colours.tertiaryFailure}
          borderWidth="2px"
          borderStyle="solid"
          alignItems="center"
          padding="small"
          margin="regular"
        >
          <Paragraph colour="tertiaryFailure" icon={onTimeIcon}>Du är sen <InfoButton title="Sen med månadsbetalningen" helpText={helpTexts.LATE} /></Paragraph>
        </FlexBox>
      )}
    </FlexBox>
    {hasPaymentPlan && paymentPlanUrl ? (
      <FlexBox justifyContent="center" marginTop="regular">
        <Anchor underlined to={paymentPlanUrl}>Se mina fakturor</Anchor>
      </FlexBox>
    ) : null}
  </Card>
);

MonthlyPayment.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  bankAccountIdPayin: PropTypes.string,
  hasPassedInvoicesPayed: PropTypes.bool,
  hasPaymentPlan: PropTypes.bool.isRequired,
  nextPaymentAmount: PropTypes.string.isRequired,
  paymentPlanUrl: PropTypes.string,
};

MonthlyPayment.defaultProps = {
  bankAccountIdPayin: undefined,
  hasPassedInvoicesPayed: undefined,
  paymentPlanUrl: undefined,
};

export default MonthlyPayment;
