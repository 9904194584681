import styled from 'styled-components';
import style from '@constants/style';

export const StyledTableCell = styled.td`
  padding: 1rem 0.5rem;
  overflow-wrap: break-word;
  text-align: ${(props) => props.textAlign || 'left'};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
  ${(props) => (props.fontWeight ? `font-weight: ${style.fontWeight[props.fontWeight]};` : '')}
`;

const TableCell = StyledTableCell;

export default TableCell;
