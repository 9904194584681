import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useGetLoan from '@utils/useGetLoan';

import { downloadAccountAgreement, downloadPublicDocument } from '@redux/actions/documents';
import AccountAgreement from '../containers/AccountAgreement';

const LoanAgreements = ({ loanId }) => {
  const documents = [];

  const { loan, loading } = useGetLoan(loanId);

  const dispatch = useDispatch();

  const product = loan && loan.product;

  const handleDownloadAgreement = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadAccountAgreement(loanId));
  }, [loanId, dispatch]);

  const handleDownloadTerms = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadPublicDocument(product, 'terms'));
  }, [dispatch, product]);

  const handleDownloadSekki = useCallback((e) => {
    e.preventDefault();
    dispatch(downloadPublicDocument(product, 'sekki'));
  }, [dispatch, product]);

  documents.push({
    title: <>Allmänna kreditvillkor</>,
    onClick: handleDownloadTerms,
  });
  documents.push({
    title: <>SEKKI</>,
    onClick: handleDownloadSekki,
  });
  documents.push({
    title: <>Kreditavtal</>,
    onClick: handleDownloadAgreement,
  });

  return (
    <AccountAgreement
      loading={loading}
      documents={documents}
    />
  );
};

LoanAgreements.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanAgreements;
