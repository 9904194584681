import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';

import InfoButton from '@components/elements/InfoButton';
import Button from '@components/elements/Button';
import Icon from '@components/elements/Icon';
import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';
import { IsActiveBadge } from '@components/elements/Badge';

import { updateBankAccountPayin } from '@redux/actions/accounts';

import getUrl from '@utils/getUrl';
import useCustomerBankAccounts from '@utils/useCustomerBankAccounts';
import useGetLoan from '@utils/useGetLoan';

import urls from '@constants/urls';
import helpTexts from '@constants/helpTexts';

import roundedCross from '@assets/img/icons/rounded-cross.svg?inline';

const NO_BANK_ACCOUNT_CONNECTED = 'Inget bankkonto anslutet';

const ConnectAutogiro = ({ loanId }) => {
  const dispatch = useDispatch();

  const { loading: loanLoading, loan } = useGetLoan(loanId);
  const isNotActive = useSelector(
    ({ settings }) => (loan && loan.status) !== settings.constants.accountStatuses.ACTIVE,
  );
  const bankAccountIdPayin = loan && loan.bankAccountIdPayin;

  const {
    loading: userLoading,
    signedBankAccounts,
    bankAccountDetails,
  } = useCustomerBankAccounts(!loanLoading && bankAccountIdPayin);

  const loading = loanLoading || userLoading;

  const removeClick = useCallback(() => {
    dispatch(updateBankAccountPayin(loanId, null));
  }, [dispatch, loanId]);

  const connectAutogiroUrl = signedBankAccounts.length
    ? getUrl(urls.HOME.AUTOGIRO.MANDATE, { accountId: loanId })
    : getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId: loanId });

  if (isNotActive) return null;

  return (
    <Card loading={loading}>
      <FlexBox marginBottom="regular">
        <Headline
          fontWeight="bold"
          fontSize="tiny"
          as="h2"
          marginBottom="none"
          marginRight="small"
        >
          Autogiro
        </Headline>
        <IsActiveBadge isActive={!!bankAccountIdPayin} />
      </FlexBox>
      <FlexBox alignItems="center" flexDirection="column">
        {bankAccountIdPayin
          ? <Paragraph>Bankkonto för närvarande anslutet: <InfoButton title="Autogiro är anslutet till ditt lån" helpText={helpTexts.AUTOGIRO_ACTIVE} /> </Paragraph>
          : <Paragraph>Ditt lån är inte anslutet till autogiro. Betalning av lånet sker manuellt via bankgiro.<InfoButton title="Autogiro" helpText={helpTexts.AUTOGIRO_NOT_ACTIVE} /></Paragraph>}
        <FlexBox
          backgroundColor="secondaryLightGray3"
          paddingTop="regular"
          paddingRight="medium"
          paddingBottom="regular"
          paddingLeft="medium"
          marginBottom="regular"
        >
          {bankAccountDetails
            ? <Paragraph marginRight="big" marginBottom="none">{bankAccountDetails.label}</Paragraph>
            : <Paragraph marginRight="big" marginBottom="none" colour="secondaryDarkGray4">{NO_BANK_ACCOUNT_CONNECTED}</Paragraph>}
          {!bankAccountIdPayin && <Icon icon={roundedCross} />}
        </FlexBox>
        {bankAccountIdPayin ? (
          <FlexBox
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            gap="medium"
            marginTop="regular"
            fullWidth
          >
            <Button
              onClick={removeClick}
              type="secondary"
              size="small"
            >
              Koppla bort
            </Button>
            <Button
              to={getUrl(urls.HOME.AUTOGIRO.MANDATE, { accountId: loanId })}
              size="small"
            >
              Byt konto
            </Button>
          </FlexBox>
        ) : (
          <FlexBox flexDirection="column" marginTop="regular">
            <Button to={connectAutogiroUrl} size="small">Anslut autogiro</Button>
          </FlexBox>
        )}
      </FlexBox>
    </Card>
  );
};

ConnectAutogiro.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default ConnectAutogiro;
